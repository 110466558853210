import { NavLink } from 'react-router-dom';
import logo from '../images/logo.jpg';

const Page403 = () => {
  return (
    <div className='flex flex-col-reverse items-center justify-center h-screen lg:flex-row bg-ext-dark text-ext-green-light'>
      <div className='text-center'>
        <NavLink to='/'>
          <img
            src={logo}
            className='h-20 p-3 mx-auto bg-white rounded-md bg-opacity-80'
            alt=''
          />
        </NavLink>
        <h1 className='my-8 text-2xl opacity-40'>
          403 | Page unauthorized for this user
        </h1>
        <NavLink to='/' className='underline'>
          Back to Dashboard
        </NavLink>
      </div>
    </div>
  );
};

export default Page403;
