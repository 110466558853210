import React, { useEffect, useState } from "react";

function FormatArea({ area, showInHa }) {
  const [areaFormat, setAreaFormat] = useState();
  useEffect(() => {
    setAreaFormat(area);
  }, [area]);
  return (
    <span>
      {/* {areaFormat > 10000 ? (
        <span>
          {Math.round((areaFormat / 1000000) * 100) / 100} km<sup>2</sup>
        </span>
      ) : (
        <span>{Math.round(areaFormat * 100) / 100} m<sup>2</sup></span>
      )} */}
      {showInHa ? (areaFormat / 10000).toFixed(3) + ' Hectares' : (areaFormat / 4046.85642).toFixed(3) + ' Acres'} 
    </span>
  );
}
export default FormatArea;
