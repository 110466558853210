import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AppHeader from '../components/AppHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faRectangleXmark,
  faEllipsisVertical,
  faCaretUp,
  faCaretDown,
  faChevronRight,
  faChevronUp,
  faChevronLeft,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import DataOnDate from '../components/farmPage/DataOnDate';
import DataChart from '../components/farmPage/DataChart';
import AppLoader from '../components/ui/AppLoader';
import AdviceModal from '../components/farmPage/AdviceModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import GeoJSON from 'ol/format/GeoJSON';
import MyMap from '../components/Mapping/components/Map/MyMap';
// import Map from "../components/Mapping/components/Map";
import {
  MyLayers,
  VectorLayer,
  TileLayer,
  ImageLayer,
} from '../components/Mapping/components/Layers';
import { get } from 'ol/proj';
import { vector, xyz, wms } from '../components/Mapping/components/Source';
// import Popup from '../components/Mapping/components/Popup/Popup';
import imgNorth from '../images/north.png';
import eviImg from '../images/evi.jpeg';
import ndviImg from '../images/ndvi.jpeg';
import ndmiImg from '../images/ndmi.jpeg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authHeader from '../services/auth-header';
import { jsPDF } from 'jspdf'; // to generate report in pdf format
import 'jspdf-autotable';
import logo from '../images/logo.jpg';
import north from '../images/north.jpg';
import { Link } from 'react-router-dom/dist';
import { Modify, Select } from 'ol/interaction';
import WKT from 'ol/format/WKT';

function FarmPage() {
  const { rootApiUrl, isLogedIn, showInHa } = useSelector((state) => state.global);
  const navigate = useNavigate();

  const params = useParams();
  const map = useSelector((state) => state.gis.map);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [farmData, setFarmData] = useState(null);
  const [farmDataOL, setFarmDataOL] = useState(null);
  const [values, setValues] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [measure, setMeasure] = useState('evi');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateExclude, setDateExclude] = useState([]);
  const [advisory, setAdvisory] = useState();
  const [showLegend, setShowLegend] = useState(false);
  const [center, setCenter] = useState([78.9629, 20.5937]);
  const [dropDownList, setDropDownList] = useState([]);
  const [imageURL, setImageURL] = useState('');
  const [legendURL, setLegendURL] = useState('');
  const [meanValue, setMeanValue] = useState(0);
  const [statsValue, setStatsValue] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectInteraction, setSelectInteraction] = useState(null);
  const [modifyInteraction, setModifyInteraction] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [coords, setCoords] = useState([]);
  const today = new Date();

  useEffect(() => {
    if (!isLogedIn) {
      navigate('/login');
    }
  }, [isLogedIn]);

  useEffect(() => {
    if (!isLogedIn) return;
    async function getFarmData() {
      setLoading(true);

      const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        redirect: 'follow',
      };

      await fetch(rootApiUrl + 'onefarm/' + params.farmId + '/', requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            navigate('/');
          }
        })
        .then((data) => {
          const userId = localStorage.getItem('userId');
          const Is_Superuser = localStorage.getItem('Is_Superuser');
          if (
            data.properties.user === parseInt(userId) ||
            Is_Superuser === 'true'
          ) {
            setFarmData(data);
            setCenter([data.properties.lon, data.properties.lat]);
            if (!data.geometry) {
              navigate('/add-farm/' + data.id);
            } else {
              setFarmDataOL(
                new GeoJSON().readFeature(data, {
                  featureProjection: get('EPSG:4326'),
                })
              );
              getMesaureValues(data.properties.user);
            }
          } else {
            navigate('/403');
          }
        });

      setLoading(false);
    }

    getFarmData();
  }, []);

  async function getMesaureValues(userId) {
    setLoading(true);

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
      redirect: 'follow',
    };

    await fetch(rootApiUrl + 'indices/?user=' + userId, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          navigate('/');
        }
      })
      .then((data) => {
        if (data.length > 0) {
          setDropDownList(data);
          const mesaureValue = data[0].name.toLowerCase();
          setMeasure(mesaureValue);
          getDateValues(mesaureValue, data[0].stats !== '');
          setStatsValue(data[0].stats !== '');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occur please check logs');
      });

    setLoading(false);
  }

  async function getAdvisory(index) {
    setLoading(true);

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };
    let url =
      rootApiUrl +
      'advisory/' +
      index +
      '/' +
      farmData?.properties.crop +
      '/' +
      farmData?.properties.sowingdate +
      '/' +
      meanValue;
    await fetch(url, requestOptions)
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            if (data.error) {
              toast.warning(data.error);
            } else {
              setAdvisory(data);
              setShowModal(true);
            }
          });
        } else {
          res.json().then((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  }

  function changeMeasureOpt(event) {
    setMeasure(event.target.value);
    getDateValues(event.target.value, true);
    getAdvisory(measure);
    setStatsValue(true);
  }

  function changeDates(value, index) {
    setSelectedValue(parseInt(index));
    setSelectedDate(value.date);
    getImagery(value.date, measure);
    meanValueMesaure(value.date, measure);
  }

  async function getDateValues(opt, statBoolen) {
    setLoading(true);

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    await fetch(rootApiUrl + 'dates/' + params.farmId, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.length > 0) {
          setValues(data);
          let excludeDates = [];
          data.forEach((x) => {
            if (x.value === null) excludeDates.push(Date.parse(x.date));
          });
          setDateExclude(excludeDates);
          setSelectedDate(data[0].date);
          getImagery(data[0].date, opt);
          if (statBoolen) {
            meanValueMesaure(data[0].date, opt);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occur please check logs');
      });
    setLoading(false);
  }

  async function getImagery(dateValue, mesauresValue) {
    setLoading(true);
    setImageURL('');
    setLegendURL('');
    setMeanValue(0);

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    await fetch(
      rootApiUrl +
        'imagery/' +
        params.farmId +
        '/' +
        mesauresValue.toUpperCase() +
        '/' +
        dateValue,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setImageURL(data.path);
        setLegendURL(data.legend);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occur please check logs');
      });
    setLoading(false);
  }

  async function meanValueMesaure(dateValue, measure) {
    const indicesValue = dropDownList.filter((val) => {
      return val.name.toLowerCase() === measure.toLowerCase();
    });
    if (indicesValue[0]?.stats === '') {
      setStatsValue(indicesValue[0].stats !== '');
      return;
    }
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    // const to_date_Value = Number(dateValue.split('-')[2]) + 1;
    // const to_date =
    //   dateValue.split('-')[0] +
    //   '-' +
    //   dateValue.split('-')[1] +
    //   '-' +
    //   to_date_Value;

    let next_Date = new Date(dateValue);
    next_Date.setDate(next_Date.getDate() + 1);
    next_Date = next_Date.toISOString().split('T')[0];

    await fetch(
      rootApiUrl +
        'statisticsUpdate/' +
        params.farmId +
        '/' +
        measure.toUpperCase() +
        '/' +
        dateValue +
        '/' +
        next_Date +
        '/' +
        'P1D',
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setMeanValue(
          data[0].data[0].outputs.data.bands.B0.stats.mean.toFixed(3)
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occur please check logs');
      });
  }

  async function filterByDate() {
    setLoading(true);

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };
    const from_date_month = startDate.getMonth() + 1;
    const to_date_month = endDate.getMonth() + 1;
    const from_date =
      startDate.getFullYear() +
      '-' +
      from_date_month +
      '-' +
      startDate.getDate();
    const to_date =
      endDate.getFullYear() + '-' + to_date_month + '-' + endDate.getDate();

    await fetch(
      rootApiUrl +
        'statisticsUpdate/' +
        params.farmId +
        '/' +
        measure.toUpperCase() +
        '/' +
        from_date +
        '/' +
        to_date +
        '/' +
        'P5D',
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let chartData = [];
        data[0].data.forEach((el) => {
          chartData.push({
            date: el.interval.from,
            value: parseFloat(el.outputs.data.bands.B0.stats.mean.toFixed(3)),
          });
        });
        setChartData(chartData);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occur please check logs');
      });
    setLoading(false);
  }

  let layerName =
    values.length > 0 &&
    'kb:' + measure + '-' + values[selectedValue]?.date + '-' + params.farmId;

  // Funtion : Calculate width
  function calculateWidth(doc, string, fontSize) {
    let value =
      (doc.getStringUnitWidth(string).toFixed(0) * fontSize) / (72 / 25.6);
    return value;
  }

  // Function : Get the current measure's legend
  function getLegend() {
    if (legendURL) {
      return rootApiUrl + legendURL;
    } else {
      if (measure === 'ndmi') {
        return ndmiImg;
      } else if (measure === 'ndvi') {
        return ndviImg;
      } else if (measure === 'evi') {
        return eviImg;
      }
    }
  }
  // Function : Generating PDF to create report
  function generatePDF(selectedAdvices, mapImage) {
    const doc = new jsPDF({ compress: true, lineHeight: 15 });

    let date = new Date().toISOString().split('T')[0];
    let legend = getLegend();

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(14);
    doc.text(`Current Date: ${date}`, 195, 15, null, null, 'right');
    doc.addImage(logo, 'JPEG', 10, 5, 45, 20);
    doc.line(10, 30, 200, 30);
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text('Farm Details: ', 10, 45);
    doc.setFontSize(14);
    doc.text(`Crop: `, 15, 55);
    doc.text(`Farm Id: `, 125, 55);
    doc.text(`Total Area: `, 15, 65);
    doc.text(`Sowing Date: `, 125, 65);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `${
        farmData?.properties.crop.charAt(0) +
        farmData?.properties.crop.slice(1).toLowerCase()
      }`,
      calculateWidth(doc, 'Total Area: ', 14) + 20,
      55
    );
    doc.text(
      `${(farmData?.properties.area / 4046.85642).toFixed(3)} Acers`,
      calculateWidth(doc, 'Total Area: ', 14) + 20,
      65
    );
    doc.text(
      `${params.farmId}`,
      calculateWidth(doc, 'Sowing Date: ', 14) + 130,
      55
    );
    doc.text(
      `${farmData?.properties.sowingdate}`,
      calculateWidth(doc, 'Sowing Date: ', 14) + 130,
      65
    );
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text(`${measure.toUpperCase()} Report: `, 10, 80);
    doc.setFontSize(14);
    doc.setFont('helvetica', 'normal');
    doc.text(`Index Date: ${values[selectedValue]?.date}`, 140, 80);
    // ! TODO UNCOMMENT AND CHECK THE CORS
    // doc.addImage(mapImage, 'JPEG', 10, 90, 130, 100);
    // doc.addImage(north, 'JPEG', 128, 92, 10, 10);
    doc.addImage(legend, 'JPEG', 150, 90, 50, 100);
    doc.setFontSize(16);
    doc.text(
      `Mean Value of ${measure.toUpperCase()}: ${
        /*values[
        selectedValue
      ]?.value.toFixed(2)*/ meanValue
      }`,
      10,
      200
    );
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text(`Advisory: `, 10, 210);
    doc.setFontSize(14);
    if (Object.keys(selectedAdvices).length) {
      let y = 230;
      for (let key in selectedAdvices) {
        if (selectedAdvices.hasOwnProperty(key)) {
          let advice = selectedAdvices[key];
          if (y >= 280) {
            doc.addPage();
            y = 10;
          }
          if (advice.length > 60) {
            let lines = doc.splitTextToSize(advice, 110);
            doc.setFont('helvetica', 'bold');
            doc.text(key.charAt(0).toUpperCase() + key.slice(1) + ': ', 15, y);
            doc.setFont('helvetica', 'normal');
            doc.text(
              lines[0],
              25 + calculateWidth(doc, 'Pest & Disease Management: ', 14),
              y
            );
            for (let i = 1; i < lines.length; i++) {
              if (y >= 280) {
                doc.addPage();
                y = 10;
              }
              y += 7;
              doc.text(
                lines[i],
                25 + calculateWidth(doc, 'Pest & Disease Management: ', 14),
                y
              );
            }
          } else {
            doc.setFont('helvetica', 'bold');
            doc.text(key.charAt(0).toUpperCase() + key.slice(1) + ': ', 15, y);
            doc.setFont('helvetica', 'normal');
            doc.text(
              advice,
              25 + calculateWidth(doc, 'Pest & Disease Management: ', 14),
              y
            );
          }
        }
        y += 12;
      }
    }
    doc.save(`${params.farmId}-${measure.toUpperCase()}-${date}.pdf`);
    toast.success('Report generated Successfully !');
  }

  // Function : Extract Map from HTML and convert it to an Image
  function exportPdf(selectedAdvices) {
    // ! TODO UNCOMMENT THIS AND REMOVE BELOW LINE AND ADD crossOrigin
    generatePDF(selectedAdvices, null);
    // const resolution = 72;
    // const dim = [420, 297];
    // const width = Math.round((dim[0] * resolution) / 25.4);
    // const height = Math.round((dim[1] * resolution) / 25.4);
    // const size = map.getSize();
    // const viewResolution = map.getView().getResolution();

    // map.once('rendercomplete', function () {
    //   const mapCanvas = document.createElement('canvas');
    //   mapCanvas.width = width;
    //   mapCanvas.height = height;
    //   const mapContext = mapCanvas.getContext('2d');
    //   Array.prototype.forEach.call(
    //     map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
    //     function (canvas) {
    //       if (canvas.width > 0) {
    //         const opacity =
    //           canvas.parentNode.style.opacity || canvas.style.opacity;
    //         mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
    //         let matrix;
    //         const transform = canvas.style.transform;
    //         if (transform) {
    //           // Get the transform parameters from the style's transform matrix
    //           matrix = transform
    //             .match(/^matrix\(([^\(]*)\)$/)[1]
    //             .split(',')
    //             .map(Number);
    //         } else {
    //           matrix = [
    //             parseFloat(canvas.style.width) / canvas.width,
    //             0,
    //             0,
    //             parseFloat(canvas.style.height) / canvas.height,
    //             0,
    //             0,
    //           ];
    //         }
    //         // Apply the transform to the export map context
    //         CanvasRenderingContext2D.prototype.setTransform.apply(
    //           mapContext,
    //           matrix
    //         );
    //         const backgroundColor = canvas.parentNode.style.backgroundColor;
    //         if (backgroundColor) {
    //           mapContext.fillStyle = backgroundColor;
    //           mapContext.fillRect(0, 0, canvas.width, canvas.height);
    //         }
    //         mapContext.drawImage(canvas, 0, 0);
    //       }
    //     }
    //   );
    //   mapContext.globalAlpha = 1;
    //   mapContext.setTransform(1, 0, 0, 1, 0, 0);
    //   let mapImage = mapCanvas.toDataURL('image/jpeg');
    //   generatePDF(selectedAdvices, mapImage);
    // });

    // // Set print size
    // const printSize = [width, height];
    // map.setSize(printSize);
    // const scaling = Math.min(width / size[0], height / size[1]);
    // map.getView().setResolution(viewResolution / scaling);
  }

  // Function : Get Map Layer depending on the name sent
  function getMapLayer(name) {
    const result = map
      .getLayers()
      .getArray()
      .filter((layer) => layer.get('name') === name)[0];
    return result;
  }

  // Function : Handle Edit Map Click
  function startEditingMap() {
    console.log('Editing...');
    setIsEditing(true);
    const mainVectorLayer = getMapLayer('farmLayer');
    const tempVectorLayer = getMapLayer('tempLayer');
    const imageOLLayer = getMapLayer('imageLayer');

    const tempVectorSource = tempVectorLayer.getSource();
    tempVectorLayer.setVisible(true);
    mainVectorLayer.setVisible(false);
    if (imageOLLayer) imageOLLayer.setVisible(false);

    const select = new Select({
      layers: [tempVectorLayer],
      wrapX: false,
    });
    const modify = new Modify({
      source: tempVectorSource,
    });
    map.addInteraction(select);
    map.addInteraction(modify);
    setSelectInteraction(select);
    setModifyInteraction(modify);
  }

  // Function : Patch Api Call to set new geometry
  async function updateGeometry(newGeometry) {
    // const sourceProjection = 'EPSG:32643';
    // const targetProjection = 'EPSG:4326';
    const reprojectedGeometry = newGeometry.clone();
    // reprojectedGeometry.transform(sourceProjection, targetProjection);
    setLoading(true);
    let wktFormat = new WKT({
      splitcollection: true,
    });
    let geometry = wktFormat.writeGeometry(reprojectedGeometry);
    let raw = JSON.stringify({
      geometry,
    });
    let requestOptions = {
      method: 'PATCH',
      headers: authHeader(),
      body: raw,
      redirect: 'follow',
    };
    await fetch(rootApiUrl + 'onefarm/' + params.farmId + '/', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        getImagery(selectedDate, measure);
        toast.success('Geometry changed Successfully !');
      })
      .catch((error) => {
        console.log('error', error);
        toast.error('Error changing Geometry !');
      });
    setLoading(false);
  }

  // Function : Save Edited Map
  function saveEditedMap() {
    console.log('Edited !');
    map.removeInteraction(selectInteraction);
    map.removeInteraction(modifyInteraction);

    const tempVectorLayer = getMapLayer('tempLayer');
    const mainVectorLayer = getMapLayer('farmLayer');
    const imageOLLayer = getMapLayer('imageLayer');

    const mainVectorSource = mainVectorLayer.getSource();
    let feature = tempVectorLayer.getSource().getFeatures()[0];
    mainVectorSource.clear();
    mainVectorSource.addFeature(feature.clone());

    tempVectorLayer.setVisible(false);
    mainVectorLayer.setVisible(true);
    if (imageOLLayer) imageOLLayer.setVisible(true);
    setIsEditing(false);

    updateGeometry(feature.getGeometry());
  }

  // Function : Cancel Editing of Map
  function cancelEditingMap() {
    console.log('Cancelled');
    map.removeInteraction(selectInteraction);
    map.removeInteraction(modifyInteraction);

    const tempVectorLayer = getMapLayer('tempLayer');
    const mainVectorLayer = getMapLayer('farmLayer');
    const imageOLLayer = getMapLayer('imageLayer');

    const tempVectorSource = tempVectorLayer.getSource();
    let feature = mainVectorLayer.getSource().getFeatures()[0];
    tempVectorSource.clear();
    tempVectorSource.addFeature(feature.clone());

    tempVectorLayer.setVisible(false);
    mainVectorLayer.setVisible(true);
    if (imageOLLayer) imageOLLayer.setVisible(true);
    setIsEditing(false);
  }

  return (
    <Fragment>
      <AdviceModal
        show={showModal}
        cropId={params.farmId}
        adviceFor={measure}
        advisory={advisory}
        generatePDF={exportPdf}
        closeModal={() => {
          setShowModal(false);
        }}
      />
      <div className='flex flex-col-reverse h-screen lg:flex-row'>
      <div
        className={`relative h-screen overflow-auto bg-ext-grey text-ext-green-light  ${
          isCollapsed ? 'basis-0' : 'basis-7/12 lg:basis-5/12'
        }`}
      >
          <ToastContainer
            position='top-center'
            closeOnClick
            pauseOnHover
            autoClose={2500}
          />
          <AppLoader show={loading} />
          <div className={isCollapsed ? 'hidden' : ''}>
          <Link to={'/'}>
            <AppHeader />
          </Link>
          <div className='bg-ext-light'>
            <div className='col-span-5 p-6 bg-ext-beige'>
              <div className='pb-2 mb-3 text-4xl capitalize font-extralight text-ext-green'>
                {farmData?.properties.name}
              </div>

              <div className='flex flex-wrap justify-between'>
                <div className='pr-2'>
                  <div className='opacity-50 text-ext-dark'>Crops</div>
                  <div className='mb-3 text-2xl capitalize font-extralight text-ext-dark'>
                    {farmData?.properties.crop}
                  </div>
                </div>
                <div className='pr-2'>
                  <div className='opacity-50 text-ext-dark'>Sowing Date</div>
                  <div className='mb-3 text-2xl capitalize font-extralight text-ext-dark'>
                    {new Date(farmData?.properties.sowingdate).toLocaleDateString('en-GB')}{' '}
                  </div>
                </div>
                <div className='pr-2'>
                  <div className='opacity-50 text-ext-dark'>Total Area</div>
                  <div className='mb-3 text-2xl font-extralight text-ext-dark'>
                    {showInHa ? (farmData?.properties.area / 10000).toFixed(3) : (farmData?.properties.area / 4046.85642).toFixed(3)}{' '}
                    <span className='text-base opacity-50 text-ext-dark'>
                    {showInHa ? 'Hectares' : 'Acers'}
                    </span>
                  </div>
                </div>
              </div>

              {values[selectedValue]?.value !== null && (
                <button
                  className='w-full p-2 mt-3 text-ext-dark border rounded bg-ext-green border-ext-green'
                  onClick={() => {
                    getAdvisory(measure);
                  }}
                >
                  Advice
                </button>
              )}
            </div>
            <div className='col-span-7 p-6'>
              <div className='flex justify-between'>
                <select
                  className='text-2xl bg-transparent font-extralight text-ext-dark'
                  value={measure}
                  onChange={changeMeasureOpt}
                >
                  {' '}
                  {dropDownList.map((e, key) => {
                    return (
                      <option
                        key={key}
                        value={e.name.toLowerCase()}
                        className='bg-ext-beige'
                      >
                        {e.name.toUpperCase()}
                      </option>
                    );
                  })}
                </select>
                <div className='relative inline-block'>
                  <button
                    type='button'
                    className='relative py-2 px-3 text-ext-dark border-0 rounded btn-secondary'
                    onClick={() => setIsOpen((prev) => !prev)}
                  >
                    Map Options
                    <span className='ml-2'>
                      <FontAwesomeIcon
                        icon={isOpen ? faCaretUp : faCaretDown}
                        className={`text-base ${
                          isOpen ? 'translate-y-0.5' : ''
                        }`}
                      />
                    </span>
                  </button>
                  {isOpen && (
                    <ul
                      className={`absolute top-full z-30 left-0 bg-ext-darker text-white border border-ext-dark border-t-0 ${
                        isOpen ? 'block' : 'hidden'
                      }`}
                    >
                      <li className='border-b'>
                        <Link
                          to={'/farms-comparison/' + params.farmId}
                          onClick={() => setIsOpen((prev) => !prev)}
                        >
                          <button className='bg-ext-beige text-ext-dark border-none text-left px-5 py-2 hover:bg-zinc-900 hover:text-ext-grey'>
                            Compare Map
                          </button>
                        </Link>
                      </li>
                      <li>
                        <button
                          className={`bg-ext-beige text-ext-dark border-none text-left px-5 py-2 hover:bg-zinc-900 hover:text-ext-grey ${
                            isEditing
                              ? 'cursor-not-allowed pointer-events-none bg-zinc-900'
                              : ''
                          }`}
                          onClick={() => {
                            setIsOpen((prev) => !prev);
                            startEditingMap();
                          }}
                        >
                          Edit Geometry
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
                {(() => {
                  if (statsValue) {
                    return (
                      <div className='opacity-50 text-ext-dark'>
                        {meanValue}
                      </div>
                    );
                  }

                  return null;
                })()}
              </div>
              {/* <div className='grid hidden grid-cols-3 gap-3'>
                <div className='text-right'>
                  <div>Max</div>
                  <div className='my-3 text-4xl font-extralight text-ext-green'>
                    0.04
                  </div>

                  <div>Min</div>
                  <div className='my-3 text-4xl font-extralight text-ext-green'>
                    -0.01
                  </div>
                </div>
                <div className='text-right'>
                  <div>Mean</div>
                  <div className='my-3 text-4xl font-extralight text-ext-green'>
                    0.01
                  </div>

                  <div>Deviation</div>
                  <div className='my-3 text-4xl font-extralight text-ext-green'>
                    0.01
                  </div>
                </div>
                <div className='text-right'>
                  <div>Median</div>
                  <div className='my-3 text-4xl font-extralight text-ext-green'>
                    0.01
                  </div>

                  <div>Num</div>
                  <div className='my-3 text-4xl font-extralight text-ext-green'>
                    40393
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className='flex items-center p-6 text-ext-dark'>
            <div className='pr-6 text-center'>
              <FontAwesomeIcon icon={faCalendarDays} className='text-2xl' />
              <br></br>
              Date
            </div>
            <div className='pb-2 overflow-y-auto'>
              <div className='flex w-max'>
                {values?.map((value, index) =>
                  value.value !== null ? (
                    <button
                      key={value.id}
                      className={
                        selectedValue === index
                          ? 'bg-ext-green bg-opacity-30 rounded-md'
                          : ''
                      }
                      type='submit'
                      onClick={() => {
                        changeDates(value, index);
                      }}
                    >
                      <DataOnDate
                        date={value.date}
                        // cloud="100%"
                        // image="100%"
                        sat={value.value.toFixed(2)}
                      />
                    </button>
                  ) : (
                    <ul className='p-3 text-left border-r rounded-lg border-ext-darker'>
                      <li className='mb-2'>
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          className='w-6 mr-2 text-lg text-ext-green'
                        />{' '}
                        {value.date}
                      </li>
                      <li className='opacity-50 text-ext-dark'>
                        Data not available
                      </li>
                    </ul>
                  )
                )}
              </div>
            </div>
          </div>

          {statsValue && (
            <div className='py-3 bg-ext-beige'>
              <div className='flex items-center justify-between px-6 xl:block 2xl:flex'>
                <div>
                  <div className='opacity-50 text-ext-dark'>Historical</div>
                  <div className='mb-3 text-4xl uppercase font-extralight text-ext-dark'>
                    {measure}
                  </div>
                </div>
                <div className='flex flex-col xl:flex-row text-black'>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      let year = date.getFullYear();
                      let month = date.getMonth() + 1;
                      let nth = date.getDate();
                      setStartDate(
                        new Date(year + '/' + month + '/' + nth + ' 0:0:0')
                      );
                    }}
                    selectsStart
                    startDate={startDate}     
                    dateFormat='dd/MM/yyyy'  //? may give error             
                    endDate={endDate}
                    maxDate={today}
                    excludeDates={dateExclude}
                    className='p-2 bg-light rounded-md xl:mr-2'
                    placeholderText='From date'
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      let year = date.getFullYear();
                      let month = date.getMonth() + 1;
                      let nth = date.getDate();
                      setEndDate(
                        new Date(year + '/' + month + '/' + nth + ' 23:59:59')
                      );
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='dd/MM/yyyy' //? may give error
                    minDate={startDate}
                    maxDate={today}
                    excludeDates={dateExclude}
                    className='p-2 mt-2 bg-light rounded-md xl:mt-0'
                    placeholderText='To date'
                  />
                  <button
                    className='mt-2 btn-secondary xl:ml-2 xl:mt-0'
                    onClick={filterByDate}
                  >
                    Update
                  </button>
                </div>
              </div>
              <DataChart data={chartData} height='300px' />
            </div>
          )}
          {!statsValue && (
            <div className='py-3 bg-ext-darker'>
              <div className='flex items-center justify-between px-6 xl:block 2xl:flex'>
                Statistics is not available for this index
              </div>
            </div>
          )}
          </div>
        </div>
        <div
        className={`relative  ${
          isCollapsed ? 'basis-full' : 'basis-5/12 lg:basis-7/12'
        }`}
      > 
        <button
          onClick={() => setIsCollapsed(p => !p)}
          className="absolute bottom-0 left-2 lg:top-2 lg:bottom-auto lg:left-0  z-10 bg-white p-1 rounded-t lg:rounded-e"
        >
          {isCollapsed ?  <>
            <FontAwesomeIcon
            className='text-xl text-ext-green hidden lg:block'
            icon={faChevronRight}
          /><FontAwesomeIcon
          className='text-xl text-ext-green block lg:hidden'
          icon={faChevronUp}
        />
          </> :  <>
            <FontAwesomeIcon
            className='text-xl text-ext-green hidden lg:block'
            icon={faChevronLeft}
          /><FontAwesomeIcon
          className='text-xl text-ext-green block lg:hidden'
          icon={faChevronDown}
        />
          </>}
        </button>
          {isEditing ? (
            <div className='absolute bottom-4 right-2 z-10 flex items-center justify-center gap-2'>
              <button
                className='py-2 px-3 text-white border-0 rounded shadow bg-ext-green'
                onClick={saveEditedMap}
              >
                Save
              </button>
              <button
                className='py-2 px-3 text-white border-0 rounded bg-red-700'
                onClick={cancelEditingMap}
              >
                Cancel
              </button>
            </div>
          ) : null}
          <span className='absolute z-10 p-3 bg-white rounded-lg shadow-lg bottom-2 lg:bottom-4 left-10 lg:left-4'>
            {!showLegend && (
              <span
                className='text-xs font-bold cursor-pointer text-ext-darker'
                onClick={() => {
                  setShowLegend(!showLegend);
                }}
              >
                <FontAwesomeIcon icon={faEllipsisVertical} className='mr-2' />{' '}
                Show Legend
              </span>
            )}
            {showLegend && (
              <>
                <FontAwesomeIcon
                  icon={faRectangleXmark}
                  className='absolute z-10 block text-2xl text-red-500 cursor-pointer -right-1 -top-1'
                  onClick={() => {
                    setShowLegend(!showLegend);
                  }}
                />
                <img
                  src={
                    legendURL
                      ? rootApiUrl + legendURL
                      : measure === 'ndvi'
                      ? ndviImg
                      : measure === 'ndmi'
                      ? ndmiImg
                      : measure === 'evi'
                      ? eviImg
                      : ''
                  }
                  alt='legend'
                  className='h-[35vh]'
                />
              </>
            )}
            {/* {measure === 'ndvi' && showLegend && (
              <img src={ndviImg} alt='legend' className='h-[35vh]' />
            )}
            {measure === 'ndmi' && showLegend && (
              <img src={ndmiImg} alt='legend' className='h-[35vh]' />
            )}
            {measure === 'evi' && showLegend && (
              <img src={eviImg} alt='legend' className='h-[35vh]' />
            )} */}
          </span>
          <MyMap center={center} zoom={5} trackLocation={false} getCoords={true} setCoords={setCoords}>
            <img
              src={imgNorth}
              alt='north'
              className='absolute z-10 object-contain p-1 rounded-md w-9 h-9 bg-ext-green-light bg-opacity-60 right-2 top-2'
            />
            <div className='absolute z-10 bottom-2 right-2 bg-ext-green-light bg-opacity-60 p-1 rounded-md text-base text-black'>
              {coords}
            </div>
            {/* <Popup type='wms' edit={isEditing} /> */}
            <MyLayers>
              <TileLayer
                source={xyz(
                  'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                )}
                zIndex={0}
              />
              {/* {values.length > 0 && (
                <TileLayer
                  name='farmWms'
                  source={wms({
                    url: 'https://gis.khetibuddy.com/geoserver/kb/wms',
                    layer: layerName,
                  })}
                  zIndex={1}
                />
              )} */}
              {farmDataOL && (
                <>
                  <VectorLayer
                    name='tempLayer'
                    color='#3399CC'
                    visible={false}
                    zIndex={3}
                    source={vector({
                      features: [farmDataOL.clone()],
                    })}
                  />
                  <VectorLayer
                    name='farmLayer'
                    zIndex='2'
                    source={vector({
                      features: [farmDataOL],
                    })}
                  />
                </>
              )}
              {imageURL && (
                <ImageLayer zIndex={1} urlPath={rootApiUrl + imageURL} />
              )}
            </MyLayers>
          </MyMap>
        </div>
      </div>
    </Fragment>
  );
}

export default FarmPage;
